import { DEFAULT_PER_PAGE } from 'mdc-constants';
import { Conjunction, GetTripsVariables, OperatorType, SortOrder } from '../../graphql';

export const userTripsScreenVariables = {
  orderBy: [
    {
      field: 'started_at',
      order: SortOrder.Desc,
    },
  ],
};

export const initialTripsVariables = {
  page: 1,
  perPage: DEFAULT_PER_PAGE,
  query: '',
  order: [{ field: 'started_at', order: SortOrder.Desc }],
  with_disabled: false,
};

export const getTripsVariables = (
  currentPage: number = initialTripsVariables.page,
  perPage: number = initialTripsVariables.perPage,
  searchQuery: string = initialTripsVariables.query,
  order: GetTripsVariables['orderBy'] = initialTripsVariables.order,
  with_disabled: boolean = initialTripsVariables.with_disabled,
): GetTripsVariables => ({
  page: currentPage,
  firstItem: perPage,
  orderBy: order,
  filter: {
    conditions: [
      {
        operator: {
          column: 'name',
          type: OperatorType.Contains,
          value: [searchQuery],
        },
        conjunction: Conjunction.Where,
      },
      {
        operator: {
          column: 'notes',
          type: OperatorType.Contains,
          value: [searchQuery],
        },
        conjunction: Conjunction.Orwhere,
      },
    ],
  },
  with_disabled: with_disabled,
});

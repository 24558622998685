import { INVENTORY_DISABLED_BOAT_RECORDS, TWO_HOURS_IN_MILLISECONDS, TWO_MINUTES_IN_MILLISECONDS } from 'mdc-constants';
import { useGraphQLClient } from 'providers';
import { useMemo } from 'react';
import { InitialDataFunction, useQueryClient } from '@tanstack/react-query';
import {
  GetExpense,
  GetExpenses,
  GetExpensesVariables,
  GetExpenseVariables,
  SortOrder,
  useGetExpense,
} from '../graphql';
import useAccessToken from './useAccessToken';
import { useLocalStorageState } from 'ahooks';

const initialTableOrder = [{ field: 'id', order: SortOrder.Desc }];

export default function useExpense(expenseId?: string | undefined, initialData?: InitialDataFunction<GetExpense>) {
  const queryClient = useQueryClient();
  const { data: accessToken } = useAccessToken();
  const { graphQLClient } = useGraphQLClient(accessToken);
  const [disabledBoatRecords] = useLocalStorageState<boolean>(INVENTORY_DISABLED_BOAT_RECORDS);

  const variables: GetExpenseVariables = {
    id: expenseId || '',
  };

  const localInitialData = useMemo<GetExpense | undefined>(() => {
    const expensesQueries = queryClient.getQueriesData<GetExpenses>({ queryKey: ['getExpenses'], exact: false });

    let initialExpenseData;

    for (let i = 0; i < expensesQueries.length; i += 1) {
      const [f, expenseData] = expensesQueries[i];
      const expense = expenseData?.expenses?.data.find((item) => item.id === variables.id);

      if (expense) {
        initialExpenseData = { expense };
        break;
      }
    }

    // console.log('The initial Expense data', initialExpenseData);

    return initialExpenseData;
  }, [queryClient, variables.id]);

  const queryInfo = useGetExpense(graphQLClient, variables, {
    cacheTime: TWO_HOURS_IN_MILLISECONDS,
    staleTime: TWO_MINUTES_IN_MILLISECONDS,
    // keepPreviousData: true,
    enabled: !!accessToken && !!expenseId,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    initialData: localInitialData || initialData,
    // initialDataUpdatedAt:,
    onSuccess: () => {
      // console.log(`Fetching trip details: [${variables.id}] succeed`);
    },
  });

  return {
    ...queryInfo,
  };
}
